// @ts-check
import React from "react";
import { Await, defer, json, redirect, useFetchers, useLoaderData, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Invitation } from "./Invitation.js";
import { PoolMember } from "./PoolMember.js";
import { useTitle } from "../AccountRoot.js";

export async function myPoolLoader({params, request}){
    let isCurrentUser = true;
    const login_url = "/login?next=" + encodeURIComponent(request.url);
    let url = "/webapi/userinfo";
    if (params.userId !== undefined){
        url = url + "/" + params.userId;
        isCurrentUser = false;
    }
    let res = await fetch(__API_URL__ + url,{
        signal: request.signal
    });
    if (res.status === 401){
        return redirect(login_url);
    }
    const user = await res.json();
    let pool = null;
    let members = [];
    if (user.licensePool !== null && user.licensePool !== ""){
        let res = await fetch(__API_URL__ + "/webapi/pool/" + user.licensePool,
            {
            signal: request.signal
        });
        if (res.status === 401){
            return redirect(login_url);
        }
        pool = await res.json();

        for (const invite of pool.invites){
            invite.created = new Date(invite.created);
        }

        for (const member_id of pool.members){
            let memberPromise = fetch(__API_URL__ + "/webapi/userinfo/" + member_id,{
                signal: request.signal
            }).then(res => res.json());
            /*if (res.status === 401){
                return redirect(login_url);
            }*/
            //const member = await res.json();
            members.push({memberPromise: memberPromise, id: member_id});
        }
    }
    let loggedInUser = null;
    if (isCurrentUser){
        loggedInUser = user;
    }
    else{
        let res = await fetch(__API_URL__ + "/webapi/userinfo",{
            signal: request.signal
        });
        if (res.status === 401){
            return redirect(login_url);
        }
        loggedInUser = await res.json();
        
    }
    return defer({user: user, licensePool: pool, members: members, loggedInUser: loggedInUser});
}



const licenses = {
    1: "Test-Next",
    2: "Keine",
    3: "telani ('Classic')",
    6: "telani Next",
    8: "Insight",
    9: "onSite",
    10: "Concept"
}

export function LicenseTypeToString(licenseType)
{
    return licenses[licenseType];
}

export function MyPool(){

    useTitle("telani Viewer verwalten");

    const params = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const invite_success = searchParams.has("invite_success") && searchParams.get("invite_success") == "true" ? true : false;

    let {user, licensePool, members, loggedInUser} = useLoaderData();

    if (licensePool === null){
        return <h2>Sie Mitglied keines Pools</h2>
    }

    let roles = loggedInUser.roles ?? [];
    if (!Array.isArray(roles))
    {
        roles = Object.values(roles);
    }

    let invites = licensePool.invites;

    // filter self
    members = members.filter((item) => user.id !== item.id);

    const removedMemberFetchers = useFetchers()?.filter((f) => f.key.startsWith("remove-member-"));
    const removedMembers = removedMemberFetchers?.map((f) => f.formData?.get("userId"));

    // this does an optimistic update, based on the data sent by the fetcher.
    if (removedMembers !== null){
        members = members.filter((item) => !removedMembers.includes(item.id.toString()));
    }

    const revokedInvitesFetchers = useFetchers()?.filter((f) => f.key.startsWith("revoke-invite-"));
    const revokedInvites = revokedInvitesFetchers?.map((f) => f.formData?.get("invitation"));

    // this does an optimistic update, based on the data sent by the fetcher.
    if (revokedInvites !== null){
        invites = invites.filter((item) => !revokedInvites.includes(item.id));
    }
    console.log(invites);
    

    const navigate = useNavigate();

    const addUser = () => {
        let user = "";
        if (params.userId !== undefined){
            user = "/" + params.userId
        }
        navigate("/account/my-pool" + user + "/add");
    }

    const addLicense = () => {
        let user = "";
        if (params.userId !== undefined){
            user = "/" + params.userId
        }
        navigate("/account/my-pool" + user + "/add-license");
    }

    let available_onsite = 0;
    let available_insight = 0;
    for (const license of licensePool.available_licenses) {
        if (license.licenseType === 9){
            available_onsite++;
        }
        if (license.licenseType === 8){
            available_insight++;
        }
    }
    let licenseAvailable = available_onsite > 0 || available_insight > 0;

    return <>
        {roles.includes("ROLE_ADMIN") && 
        <div className="poolAdminArea">
        <h4>Admin</h4>
        <p>Name: {licensePool.name}</p>
        <input type="button" onClick={addLicense} value="Lizenz hinzufügen"></input>
        </div>
        }
        {invite_success && <div className="alert alert-success">Einladung wurde erfolgreich versendet.</div>}
        <h4 className="poolSubHeader">Aktuelle Viewer-Nutzer:</h4>
        {members.length !== 0 ? <>
            {members.map((obj) => 
                <React.Suspense key={obj.id} fallback={<p>Laden...</p>}>
                    <Await
                    resolve={obj.memberPromise}
                    errorElement={
                        <p>Laden fehlgeschlagen.</p>
                    }>
                        {(member) => (
                <PoolMember key={member.id} memberId={member.id} 
                    email={member.email} licenseType={member.licenseType} 
                    member={member}
                    poolId={licensePool.id} />
                        )}
                    </Await>
                </React.Suspense>
            )}</>
        : <p>Noch keine verwaltete Viewer-Nutzer.</p>}

        {invites !== undefined && invites !== null && invites.length > 0 &&
        <>
            <h4 className="poolSubHeader">Ausstehende Einladungen:</h4>
            {invites.map((invite) => 
            <Invitation key={invite.id} id={invite.id} email={invite.email} licenseType={invite.licenseType} created={invite.created} />
            )}
        </>
        }
            <p className="addUserButton">
                <input type="button" disabled={!licenseAvailable} className="primary" 
                    onClick={addUser} value="Viewer-Lizenz an Nutzer vergeben" />
            </p>

        <div>
        {licenseAvailable && <>
            <h4 className="poolSubHeader">Verfügbare Lizenzen:</h4>
            <ul>
                {available_insight > 0 && <li>telani Insight: {available_insight}</li>}
                {available_onsite > 0 && <li>telani onSite: {available_onsite}</li>}
            </ul>
        </>}
        </div>

        <p>Sie können weitere telani Insight und onSite Lizenzen erwerben. Schreiben Sie 
            uns: <a href="mailto:info@telani.net">info@telani.net</a></p>
    </>
}
